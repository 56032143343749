
<template>




    <div>

        <div class="market-profile-inner">
            <ul>
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">EPS</div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                    <div class="header-actions-sw">
                        <div :class="'header-actions-sw-item ' + (type == 'sell' ? 'sw-item-active' : '')" @click="updateType('sell')">سرمایه برآورد</div>
                        <div :class="'header-actions-sw-item ' + (type == 'buy' ? 'sw-item-active' : '')" @click="updateType('buy')">آخرین سرمایه</div>
                    </div>
                </li>
            </ul>
        </div>


        <div v-for="(item, index) in stock_eps['1']" v-if="stock_eps && type == 'sell'" :key="index" class="market-main-item">
            <div class="market-main-item-row cursor-pointer" @click="update_active_li(index)">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-title">
                        <h2>سال مالی</h2>
                    </div>
                    <div class="market-main-item-time"><i class="uil uil-clock"></i><span>{{ index }}</span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price market-title-item-flex" v-for="(item_info, index_info) in item.info" :key="index_info">
                        <span class="market-title-item">{{ item_info.key }}</span>
                        <span :class="'market-title-item-value ' + (item_info.value < 0 ? 'i-row-low' : '')" >{{ item_info.value }}</span>
                    </div>
                </div>
            </div>
            <div class="market-profile-inner market-profile-inner-list-2" v-if="active_li == index">
                <ul>                    
                    <li v-for="(item_rows, index_rows) in item.rows" :key="index_rows" v-if="item.rows">
                        <div class="profile-inner-table-row profile-inner-table-row-head cursor-pointer" @click="update_active_sub_li(index_rows)">
                            <div class="profile-inner-table-name">تاریخ</div>
                            <div class="profile-inner-table-value">{{ item_rows.date + ' ' + item_rows.cycle }}</div>
                        </div>
                        <slot v-if="active_sub_li == index_rows">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سود : {{ item_rows.mohaghagh ? 'پیش بینی' : 'واقعی'}}</div>
                                <div class="profile-inner-table-value"> {{ item_rows.mohaghagh ? item_rows.pishbini : item_rows.vaghei}}</div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">{{ item_rows.mohaghagh ? 'محقق' : 'نقدی'}}</div>
                                <div class="profile-inner-table-value">{{ item_rows.mohaghagh ? value(item_rows.mohaghagh) : value(item_rows.naghdi)}}({{ item_rows.mohaghagh ? percentage(item_rows.mohaghagh) : percentage(item_rows.naghdi) }}%)</div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سرمایه</div>
                                <div class="profile-inner-table-value">{{ item_rows.sarmaye }}</div>
                            </div>
                        </slot>
                    </li>
                </ul>
            </div>
            
        </div>
        
        <div v-for="(item, index) in stock_eps['2']" v-if="stock_eps && type == 'buy'" :key="index" class="market-main-item">
            <div class="market-main-item-row cursor-pointer" @click="update_active_li(index)">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-title">
                        <h2>سال مالی</h2>
                    </div>
                    <div class="market-main-item-time"><i class="uil uil-clock"></i><span>{{ index }}</span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price market-title-item-flex" v-for="(item_info, index_info) in item.info" :key="index_info">
                        <span class="market-title-item">{{ item_info.key }}</span>
                        <span :class="'market-title-item-value ' + (item_info.value < 0 ? 'i-row-low' : '')" >{{ item_info.value }}</span>
                    </div>
                </div>
            </div>
            <div class="market-profile-inner market-profile-inner-list-2" v-if="active_li == index">
                <ul>                    
                    <li v-for="(item_rows, index_rows) in item.rows" :key="index_rows" v-if="item.rows">
                        <div class="profile-inner-table-row profile-inner-table-row-head cursor-pointer" @click="update_active_sub_li(index_rows)">
                            <div class="profile-inner-table-name">تاریخ</div>
                            <div class="profile-inner-table-value">{{ item_rows.date + ' ' + item_rows.cycle }}</div>
                        </div>
                        <slot v-if="active_sub_li == index_rows">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سود : {{ item_rows.mohaghagh ? 'پیش بینی' : 'واقعی'}}</div>
                                <div class="profile-inner-table-value"> {{ item_rows.mohaghagh ? item_rows.pishbini : item_rows.vaghei}}</div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">{{ item_rows.mohaghagh ? 'محقق' : 'نقدی'}}</div>
                                <div class="profile-inner-table-value">{{ item_rows.mohaghagh ? value(item_rows.mohaghagh) : value(item_rows.naghdi)}}({{ item_rows.mohaghagh ? percentage(item_rows.mohaghagh) : percentage(item_rows.naghdi) }}%)</div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سرمایه</div>
                                <div class="profile-inner-table-value">{{ item_rows.sarmaye }}</div>
                            </div>
                        </slot>
                    </li>
                </ul>
            </div>
            
        </div>
        

        <!-- <div v-for="(item, index) in stock_eps['1']" v-if="stock_eps" :key="index">
        سال مالی {{ index }} &nbsp;&nbsp;&nbsp;
            <span v-for="(item_info, index_info) in item.info" :key="index_info">
            {{ item_info.key + ' ' + item_info.value }}&nbsp;&nbsp;&nbsp;
            </span><br>
            <div v-for="(item_rows, index_rows) in item.rows" :key="index_rows">
            تاریخ&nbsp;&nbsp;&nbsp;{{ item_rows.date + ' ' + item_rows.cycle }}<br>
            سود&nbsp;&nbsp;&nbsp;{{ item_rows.mohaghagh ? 'پیش بینی' : 'واقعی'}} {{ item_rows.mohaghagh ? item_rows.pishbini : item_rows.vaghei}}&nbsp;&nbsp;&nbsp;
            {{ item_rows.mohaghagh ? 'محقق' : 'نقدی'}} {{ item_rows.mohaghagh ? value(item_rows.mohaghagh) : value(item_rows.naghdi)}}({{ item_rows.mohaghagh ? percentage(item_rows.mohaghagh) : percentage(item_rows.naghdi) }}%)<br>
            سرمایه&nbsp;&nbsp;&nbsp;{{ item_rows.sarmaye }}<br>
            ##########<br>
            </div>
        ----------------------------------------------------------<br>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'StockEps',
    data() {
        return {
            type:'sell',
            stock_eps : null,
            active_li: null,
            active_sub_li: null,
        }
    },
    mounted() {
        this.getProfileEps();
    },
    methods: {
        updateType(type) {
            this.type = type;
        },
        update_active_sub_li(active_sub_li) {
            if(this.active_sub_li == active_sub_li){
                this.active_sub_li = null;
            }else{
                this.active_sub_li = active_sub_li;
            }
        },
        update_active_li(active_li) {
            if(this.active_li == active_li){
                this.active_li = null;
            }else{
                this.active_li = active_li;
            }
            this.active_sub_li = 0;
        },
        value(item) {
            if (item == null) {
                return '-';
            }
            return JSON.parse(item).value ?? '-';
        },
        percentage(item) {
            if (item == null) {
                return '-';
            }
                return JSON.parse(item).percentage ?? '-';
        },
        getProfileEps() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-eps/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_eps = api_response.data.response.eps.body.response.items;
                        this.widgetLoad = false;
                        // this.stock_header_summary = api_response.data.response.detail.response.summary;
                        // $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        // $('body').addClass('internal profile fs-grid mprofile');
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>